import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import user from '../features/user/userSlice';

import { combineReducers } from 'redux'

const reducer = combineReducers({
  // here we will be adding reducers
  counter: counterReducer,
  user
})

export const store = configureStore({
  reducer
});
