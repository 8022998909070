import React, { useState, useEffect } from 'react'
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
//import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios';
import {logoutSuccess} from './features/user/userSlice.js'
import Top from './Header';
import Nav from './nav';
import { TableIODef } from './tableIODef';
import { Counter } from './features/counter/Counter.js'
import Signature from './signature.js'

function MainPage() {
  const [ selfData, setSelfData ] = useState([])
  const [ allMachines, setAllMachines ] = useState([])
  const dispatch = useDispatch();

  const driverList = async () => {
    // let token = localStorage.getItem('token');
    // if (token) {
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // }
    try {
      const result = await axios.post('http://localhost:3000/user/ownerrelations');
      console.log(result.data);
     
    } catch (err) {
      console.log(err);
      console.log('undra om vi kommer hi...')
       dispatch(logoutSuccess());
    }
  }

  const driverMachineList = async () => {
    // let token = localStorage.getItem('token');
    // if (token) {
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // }
    try {
      const result = await axios.post('http://localhost:3000/user/drivermachinerelations');
      console.log(result.data);
    } catch (err) {
      console.log(err);
      dispatch(logoutSuccess());
    }
  }



  const allService = async () => {
    try {
      const result = await axios.get('http://localhost:3000/service/getallserviceactions')
      console.log(result.data);

    } catch (err) {
      console.log(err)
      dispatch(logoutSuccess());
    }
  }

  const datamach = { machineId: 1 }
  const msg = {
    msg: 'Första från EcoLogInsight. NexSci Harvester is due to service in 50h. Contact your service provider to book a time',
    subject: 'From Your Dealer - EcoLog'
  }

  const serviceMachineId = async (data) => {
    console.log(data);
    try {
      const result = await axios.post('http://localhost:3000/service/getservicesformachineid', data)
      console.log(result.data);

    } catch (err) {
      console.log(err)
      dispatch(logoutSuccess());
    }
  }

  const allMachinesReq = async (data) => {
    console.log(data);
    try {
      const result = await axios.get('https://ecologportal.duckdns.org/fleetapi/machine/all')
      console.log(result.data);
      setAllMachines(result.data);

    } catch (err) {
      console.log(err)
      //dispatch(logoutSuccess());
    }
  }

  const sendWeMail = async (data) => {
    console.log(data);
    // let token = localStorage.getItem('token');
    // if (token) {
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    // }
    try {
      const result = await axios.post('http://localhost:3000/mail/sendmemail', data)
      console.log(result.data);

    } catch (err) {
      console.log(err)
      dispatch(logoutSuccess());
    }
  }

  useEffect(() => {
    const selfReq = async () => {
      // let token = localStorage.getItem('token');
      // if(token) {
      //     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // }
      try {
          const result = await axios.post('http://localhost:3000/user/self');
          console.log(result.data);
          setSelfData(result?.data);
      } catch(err) {
          console.log(err);
          dispatch(logoutSuccess());
      }
    }

    //selfReq();
  }, []);

  return (
    <div className="MainPage">
      <div className="mainSvg">
        <ServiceBig />
        {/* <Signature /> */}
      </div>
      <Top />
      <div className="maniCon">
        <Nav />
        <div className="mainPageBtn">
          <div className="mainPageBtnBox">
            <button className="button button2" onClick={driverList}>Get Driver List</button>
            <button className="button button2" onClick={driverMachineList}>Get DriverMachine List</button>
            <button className="button button3" onClick={allService}>allService</button>
            <button className="button button3" onClick={() => serviceMachineId(datamach)}>serviceMachineId</button>
            <button className="button button3" onClick={() => sendWeMail(msg)}>sendWeMail</button>
            <button className="button button3" onClick={() => allMachinesReq()}>AllMachines</button>
          </div>
          <div className="mainTableBox">
            <TableIODef
              dataIO={allMachines}
            />
          </div>
         </div>
      </div>
      <Routes>
        <Route path="counter" element={<Counter />} />
        <Route path="service" element={<Service />} />
      </Routes>
    </div>
 
  )
}

export default MainPage

const Row = ({ machineId, engine, serialnr, type }) => {
  return (
    <tr>
      <td >{machineId}</td>
      <td>{engine}</td>
      <td>{serialnr}</td>
      <td>{type}</td>
    </tr>
  )
}

const Table = ({ data }) => (
  <tbody>
    { data?.length > 0 &&
      data.map(({ machineId, engine, serialnr, type }) => (
        <Row key={machineId} machineId={machineId} engine={engine} serialnr={serialnr} type={type} />
      ))
    }
  </tbody>
)

const TableService = ({ dataIO }) => {
  return (
    <>
      <div>
        <table >
          <thead>
            <tr>
              <th>MachineId</th>
              <th>Engine</th>
              <th>Serialnr</th>
              <th style={{color: 'grey'}}>type</th>
            </tr>
          </thead>
            <Table data={dataIO} />
        </table >
      </div>
    </>
  );
}

const Service = () => {
  const [ machines, setMachines ] = useState([])

  const allMachines = async () => {
    try {
      const result = await axios.get('http://localhost:3000/machine/all')
      console.log(result.data);
      setMachines(result?.data);

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="serviceMain">
      ServicePage
      <div className="serviceBox" onClick={allMachines}>
        <TableIODef
            dataIO={machines}
          />
      </div>
    </div>
  )
}

export const ServiceBig = ({ fill='#F0F0F0'}) => (
  <svg width="250" height="250" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
    <path d="M49.2802 16.1465C51.2819 11.5426 48.9799 5.93776 43.9756 2.63492C43.6753 2.43475 43.3751 2.63492 43.1749 2.93518L40.2724 10.9421L34.5675 8.94034L37.47 0.833367C37.5701 0.533109 37.3699 0.232851 37.0696 0.132764C31.8652 -0.567838 26.9609 1.53397 25.0593 5.73759C24.4588 7.23888 24.2586 8.74017 24.4588 10.3415C24.8591 13.7445 23.8583 17.0473 21.4562 19.4494C15.8514 24.9541 6.4433 34.162 1.43899 39.0663C-0.462645 40.8678 -0.462645 43.8704 1.33891 45.772L1.63916 46.0723C3.44071 47.9739 6.4433 47.9739 8.34493 46.1724L29.4631 25.4545C31.8652 23.0525 35.168 21.9515 38.5709 22.0516C43.4752 22.0516 47.5787 19.9498 49.2802 16.1465ZM6.84364 43.1698C6.04295 43.9705 4.74183 43.8704 4.04123 42.9696C3.5408 42.269 3.64089 41.2682 4.34149 40.5676L26.1603 19.1491C26.8609 18.4485 28.2621 18.6487 28.5623 19.5495C28.9627 20.4502 28.8626 21.4511 28.162 22.0516L6.84364 43.1698ZM39.3716 18.4485C36.8695 18.5486 35.2681 18.4485 33.7668 17.848C32.1654 17.2475 30.9644 16.3467 30.3639 15.3459C29.0628 13.3441 28.6624 11.5426 28.5623 10.0413C28.4622 8.54 28.6624 7.53914 29.0628 6.63836C29.7634 5.03698 31.2647 3.93604 33.1663 3.33552L31.3647 8.43991C30.9644 9.54086 31.5649 10.7419 32.6659 11.1422L41.0731 14.1448C42.174 14.5452 43.4752 13.9446 43.8755 12.8437L45.677 7.93948C47.0783 9.9412 47.4786 12.2432 46.6779 14.1448C45.4769 16.4468 42.6745 18.3484 39.3716 18.4485Z" fill={fill}/>
    <path d="M43.3752 35.3631L30.0638 21.4512L20.0552 31.2596L33.3666 45.2717C34.7678 46.6729 36.5694 47.3735 38.3709 47.3735C40.1725 47.3735 41.974 46.6729 43.2752 45.3717C46.0776 42.6694 46.1776 38.1656 43.3752 35.3631ZM41.1733 43.1699C40.3727 43.8705 39.3718 44.2708 38.3709 44.2708C37.27 44.2708 36.2691 43.8705 35.5685 43.0698L24.2588 31.3597L29.9637 25.7549L41.1733 37.565C41.974 38.3657 42.3744 39.3666 42.2743 40.4675C42.3744 41.4684 41.974 42.4693 41.1733 43.1699Z" fill={fill}/>
    <path d="M22.8574 21.8514L12.7487 11.1422C13.9497 9.84109 13.7496 7.83937 12.3483 6.73842L5.94283 1.93429C5.64258 1.73412 5.34232 1.63403 5.04206 1.63403C4.64172 1.63403 4.34146 1.73412 4.0412 2.03438L1.93939 4.0361C1.43896 4.53653 1.33887 5.33722 1.73922 5.93774L6.24309 12.6435C6.84361 13.5443 7.74438 13.9446 8.74524 13.9446C9.44585 13.9446 10.0464 13.7445 10.6469 13.244L20.7556 23.9532L22.8574 21.8514ZM8.64516 10.942L4.94197 5.33722L5.14215 5.13705L10.4467 9.14049L8.64516 10.942Z" fill={fill}/>
  </svg>
)