import React from 'react';
import { useSelector } from 'react-redux';
//import logo from './logo.svg';
//import { Counter } from './features/counter/Counter';
import './App.css';
import RoutesMain from './routesMain';
import LoginPage from './login.js'

function App() {
  //const { user } = useSelector(state => state.user)
    {/*
    if (user) {
      return (
        <div className="main_Con"> 
          <RoutesMain />
        </div>
      );
    }

    return <LoginPage />
  */}
  return <RoutesMain />

}

export default App;
