import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

 function setAuthToken(token) {
  axios.defaults.headers.common['Authorization'] = '';
  delete axios.defaults.headers.common['Authorization'];

  if (token) {
    axios.defaults.headers.common['Authorization'] = `${token}`;
  }
}

 let token = localStorage.getItem('token');
 if(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

let user = localStorage.getItem('user') ?? null;
let loggedin = localStorage.getItem('loggedin') ?? false;

// Slice
const slice = createSlice({
  name: 'user',
  initialState: {
    user: user,
    loggedIn: loggedin,
  },
  reducers: {
    loginSuccess: (state, action) => {
      //state.user = action.payload;
      //localStorage.setItem('user', JSON.stringify(action.payload))
      //localStorage.setItem('token', result.data.accessToken);
    },
    logoutSuccess: (state, action) =>  {
      state.user = null;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('loggedin');
      axios.defaults.headers.common['Authorization'] = '';
      delete axios.defaults.headers.common['Authorization'];
      state.loggedIn = false;
      //localStorage.setItem('token', result.data.accessToken);
    },
    setUser: (state, action) => {
      console.log(action.payload)
      state.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload))
    },
    loggedIn: (state, action) => {
      state.loggedIn = action.payload;
      //localStorage.setItem('user', JSON.stringify(action.payload))
      //localStorage.setItem('token', result.data.accessToken);
    },
  },
});
export default slice.reducer
// Actions
export const { loginSuccess, logoutSuccess, setUser, loggedIn } = slice.actions
export const login = ({ username, password }) => async dispatch => {
  try {
    // const res = await api.post('/api/auth/login/', { username, password })
    dispatch(loginSuccess({username}));
  } catch (e) {
    return console.error(e.message);
  }
}
export const logout = () => async dispatch => {
  try {
    // const res = await api.post('/api/auth/logout/')
    dispatch(logoutSuccess())
  } catch (e) {
    return console.error(e.message);
  }
}